import React from 'react'
import { Link } from 'gatsby'
import Layout from 'components/layout'

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <h1>About</h1>
    <p>Welcome to About Page</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default NotFoundPage
